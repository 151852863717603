import React from 'react';
import { useConnect, useAccount } from "wagmi"




function Modal({ isOpen, onClose, rightImage, rightText }) {
    const { connectors, connect } = useConnect()
    const { isConnected } = useAccount()
    if (!isOpen) return null;

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const restrictWallet = (id) => {
        if (id === "metaMaskSDK" || id === "coinbaseWalletSDK" || id === "walletConnect") {
            return true
        } else {
            return false
        }
    }

    return !isConnected && (
        <div
            className="fixed inset-0 z-50 flex urbanist items-center justify-center bg-gray-800 bg-opacity-75"
            onClick={handleBackgroundClick} // Add soft close when clicking the background
        >
            <div className="relative bg-[#181414] rounded-lg p-8 w-[95%] sm:w-[85%] md:w-[65%] lg:w-[55%] flex flex-col md:flex-row fade-up">
                {/* Close Button */}
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full bg-gray-300 hover:bg-gray-400 text-black"
                >
                    &times; {/* Close icon (X) */}
                </button>

                {/* Left Side: Mapped Items */}
                <div className="w-full md:w-[45%] pr-6 flex flex-col gap-4 mb-6 md:mb-0">
                    {connectors.map((connector, index) => restrictWallet(connector.id) && (
                        <button
                            key={index}
                            className="flex items-center rounded-md gap-4 p-2 w-full hover:bg-[#4b3e28] transition-colors duration-300"
                            onClick={() => connect({ connector })}
                        >
                            <img src={connector?.name === "MetaMask" ? "/asset/MetaMask_Fox.svg.png" : connector.name === "Coinbase Wallet" ? "/asset/coinbase.svg" : "/asset/wallet-connect.png"} alt={connector.name} className="h-10 w-10" />
                            <span className="text-lg font-bold text-white">{connector.name}</span>
                        </button>
                    ))}
                </div>

                {/* Vertical Divider */}
                <div className="hidden md:block h-auto self-stretch w-px bg-gray-500 mx-6"></div>

                {/* Right Side: Image and Text */}
                <div className="w-full md:w-[55%] flex flex-col items-center gap-6 p-4">
                    <img src={rightImage} alt="Right Image" className="h-24 w-24 object-contain" />
                    <p className="text-gray-300 text-center">{rightText}</p>

                    {/* Learn More Button */}
                    <a target='_BLANK' href="https://learn-more" className="mt-4 inline-block px-6 py-2  text-[#ffc966] rounded-full hover:bg-[#4b3e28]">
                        Learn More
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Modal;
