import './App.css';
import StopPlaying from './stopplaying'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { config } from './wagmi';
import React from 'react';
import Landing from './landing';



const queryClient = new QueryClient()

function App() {
  return (
    
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <StopPlaying>
          <Landing />
        </StopPlaying>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
