import logo from './logo.svg';
import './App.css';
import { bgSvg, dotS, ftSvg } from './svg';
import { useState } from 'react';
import Modal from './MOdal';
import { StopPlayingContext } from "./stopplaying"
import React from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import { fantom } from 'wagmi/chains';

export default function Landing() {
  const { isConnected, chainId } = useAccount()
  const { switchChain } = useSwitchChain()
  const { sendTransaction } = React.useContext(StopPlayingContext)
  const [openIndices, setOpenIndices] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleQuestion = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter(i => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  const rightImage = '/asset/logo-2.svg';
  const rightText = 'Sonic enables developers to build powerful decentralized apps with ease.';

  const imgItm = [
    { title: "Sonic Migration", sub: "$FTM Migration to $S is now live, migrate your $FTM to $S at 1:1 conversion rate.", icon: "/asset/note-pen.png", btnText: !isConnected ? "Migrate" : chainId !== fantom?.id ? "Switch to Sonic Network" : "Begin Migration" },
    { title: "What are Sonic Gems?", sub: "A unique incentive system for apps participating in Sonic Boom.", icon: "/asset/gem.svg", btnText: "Learn More" }
  ];

  const newItm = [
    { title: "Emrald", sub: "10 places: Awarded a mountain of Gems.", icon: "./asset/green.svg" },
    { title: "Sapphire", sub: "10 places: Awarded a substantial amount of Gems", icon: "/asset/blue.svg" },
    { title: "Ruby", sub: "10 places: Awarded a fair amount of Gems", icon: "/asset/ruby.svg" }
  ]

  const lstItm = [
    { title: "GasM", sub: "As we expand our Gas Monetization (GasM) program from 15% to 90% include a strategy to leverage GasM in your proposal, and you'll get a bonus", icon: "/asset/gas.svg" },
    { title: "Safe AA", sub: "We've partnered with Safe AA to offer additional points if you utilize their technology", icon: "/asset/safe.svg" },
    { title: "Sonic Native", sub: "Apps that are unique and exclusively developed for Sonic with a new token will receive extra points and are highly encouraged", icon: "/asset/icn.svg" },
  ]

  const faqDta = [
    { question: "What is Sonic Boom?", answer: "The Sonic Boom program is an initiative by Sonic Labs designed to drive early growth on Sonic by attracting top-tier projects and fostering the development of essential applications within our ecosystem. Sonic Gems represent a meritocratic system designed to empower apps in distributing rewards. This allocation makes Gems a crucial tool for developers looking to leave their mark on the Sonic network." },
    { question: "How Do I Apply to Sonic Boom?", answer: "To submit your project, ensure your proposal includes a detailed description of the protocol, its use cases, its potential to drive user adoption on Sonic, whether it leverages Sonic features (like Gas Monetization and Safe tools), and a timeline for deployment." },
    { question: "Who can participate in Sonic Boom?", answer: "Any developer or team capable of creating protocols that align with the program’s objectives is eligible to participate. These protocols must be deployed on the upcoming Sonic testnet and be deployed on the Sonic mainnet within 7 days of its launch." },
    { question: "What is the purpose of Sonic Gems", answer: "Sonic Gems are the primary rewards in the Sonic Boom program. They represent points that can be used to incentivize user engagement within the Sonic ecosystem. Winning projects can distribute these Gems to users as rewards for interacting with their protocols, helping to bootstrap and sustain user activity." },
    { question: "Are there participation Fees?", answer: "Participation in Sonic Boom is free. However, participants are responsible for any gas fees or other costs associated with deploying their protocols on the Sonic testnet and mainnet." },
    { question: "What If a Project Fails to Launch on Mainnet in Time?", answer: "If a project fails to launch on the Sonic mainnet within the required 7-day window, it risks forfeiting the awarded Sonic Gems. However, the project may still be recognized for its innovation and may receive other forms of support from Sonic Labs." },
    { question: "Can existing projects participate?", answer: "Yes, existing projects that meet the program’s criteria can participate as long as they deploy on the upcoming Sonic testnet and follow the program rules." },
    { question: "Are there participation fees?", answer: "Participation in Sonic Boom is free. However, participants are responsible for any gas fees or other costs associated with deploying their protocols on the Sonic testnet and mainnet." }
  ]

  return (
    <div className="flex flex-col bg-[#181414] text-[#fff]">

      <header className='fixed top-0 w-full bg-[#181414] z-50'>
        <nav className="px-[1rem] py-[1rem] md:px-[10rem] w-full items-center justify-between mx-auto">
          <a className='cursor-pointer' onClick={() => {
            window.scrollTo(0,0)
          }}>
            <img src="/asset/sonic-logo.svg" alt="Logo" className="h-8 w-auto" />
          </a>
        </nav>
      </header>

      <div className='relative rounded-b-3xl overflow-hidden'>
        <div className='absolute w-full h-full opacity-70'>{bgSvg}</div>
        <div className='absolute flex flex-wrap w-full h-full backdrop-blur-3xl'>{dotS}</div>

        <div className="flex-1 relative pt-[6rem] pb-[6rem] md:pb-[4rem] z-10 flex flex-col items-center gap-y-8 mx-auto px-4 md:px-8 mt-[4rem] mb-[0] md:mb-[4rem]">
          <img className="w-full max-w-[896px] h-auto" src="/asset/boom.svg" alt="Sonic Logo" />

          <p className="text-white text-center urbanist max-w-2xl text-[1.125rem] md:text-[1.5rem] m-auto px-[5%] md:px-4">
            A unique bounty program built on Sonic. Enabling builders of all kinds to engage with our tech and community to earn Sonic Gems for our upcoming airdrop!
          </p>
        </div>
      </div>


      <div className="-mt-24 md:-mt-24 mx-auto container relative urbanist px-4 md:px-0">
        <div className="absolute top-10 left-0 md:left-40 w-full md:w-[896px] relative rounded-3xl h-[12rem] md:h-[17rem] flex" style={{ boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1)", backgroundImage: "linear-gradient(-45deg, #214e81 12.5%, #506179 25%, #ed5409 37.5%, #ffcb67 50%, #ffcb67 0, #ed5409 62.5%, #506179 75%, #214e81 87.5%", animation: "gradient 3s linear infinite", backgroundSize: "200% auto" }}>
          <div className="flex-1 m-0.5 bg-[#181414] rounded-3xl flex flex-col items-center justify-center text-center break-words gap-y-2">
            <div className='w-full flex items-center justify-center text-center break-words flex-col xl:flex-row gap-x-2'>
                <span className="text-[1.25rem] md:text-[3rem] font-medium">Up to</span>
                <span className="text-[2rem] md:text-[6rem] font-medium">$ 34,999,232*</span>
            </div>

            <div className='w-[70%] md:w-[40%] mt-[0px] md:mt-[-10px] flex justify-center'><button className="w-full inline-flex items-center justify-center rounded-full bg-[#393022] text-[#ffc966] font-bold leading-normal px-4 py-4 hover:bg-[#4b3e28]" onClick={() => {
                if(!isConnected) {
                    setIsModalOpen(true)
                }
                else {
                    if(chainId !== fantom?.id) {
                        switchChain({ chainId: fantom?.id })
                    }
                    else {
                        sendTransaction?.()
                    }
                }
            }}>
                {!isConnected ? "Migrate Now" : chainId !== fantom?.id ? "Switch to Sonic Network" : "Begin Migration"}
            </button></div>
          </div>
        </div>
      </div>


      <div className="items-center urbanist justify-center flex mt-16">
        <span className="font-body-lg text-gray-500">* 71,437,500 FTM</span>
      </div>


      <div className='w-full urbanist px-4 md:px-[18%] justify-between flex flex-col md:flex-row gap-4 mt-16'>
        {imgItm.map((item, index) => (
          <div key={item.title} className="w-full md:w-[48%] rounded-2xl p-6 border border-shade-2 flex flex-col items-start gap-y-2 h-[350px]">

            {/* Image */}
            <img src={item.icon} alt={item.title} className="h-20 object-contain" />

            {/* Title and Subtitle */}
            <div className="flex flex-col items-start gap-y-2">
              <h6 className="text-[1rem] md:text-[2rem] font-bold">{item.title}</h6>
              <p className="text-[1.2rem] max-w-xs">{item.sub}</p>
            </div>

            {/* Button */}
            <div className="mt-auto">
              <button className="inline-flex items-center justify-start rounded-full bg-[#393022] text-[#ffc966] font-bold leading-normal p-4 h-4 hover:bg-[#4b3e28]"  onClick={() => {
                if(index === 0) {
                    if(!isConnected) {
                        setIsModalOpen(true)
                    }
                    else {
                        if(chainId !== fantom?.id) {
                            switchChain({ chainId: fantom?.id })
                        }
                        else {
                            sendTransaction?.()
                        }
                    }
                }
                else {
                  window.open("https://blog.soniclabs.com/sonic-gems-and-sonic-boom-bounty-program/")
                }
              }}>
                {item.btnText}
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* Modal Component */}
      <Modal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        rightImage={rightImage}
        rightText={rightText}
      />


      <div className="mt-16 md:mt-32 p-4 mx-auto flex flex-col gap-y-8 w-full md:w-[1024px]">
        {/* Title */}
        <h3 className='text-[2rem] md:text-[3rem] text-left leading-8 urbanist self-start'>Rankings</h3>

        {/* Mapped Items Div */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {newItm.map((item) => (
            <div key={item.title} className="w-full rounded-2xl p-6 border border-shade-2 flex items-start gap-x-4 h-auto">

              {/* Icon */}
              <div className="w-24 h-24 flex-shrink-0">
                <img src={item.icon} alt={item.title} className="w-full h-full object-contain" />
              </div>

              {/* Title and Subtitle in a row */}
              <div className="flex flex-col justify-center">
                <h6 className="text-[1.25rem] md:text-[1.5rem] font-bold">{item.title}</h6>
                <p className="text-body-lg">{item.sub}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='w-full md:w-[1024px] mx-auto urbanist gap-y-8 flex flex-col gap-4 mt-16 p-4'>
        <h3 className='text-[2rem] md:text-[3rem] leading-8'>Bounty List</h3>
        <p className='max-w-[28rem] text-[1.125rem]'>
          The bounty program covers a wide range of categories to encourage diverse and innovative applications. Multiple winners can be selected in each category. To qualify, your app must fit into one of the following categories:
        </p>
      </div>


      <div className="mt-16 md:mt-32 p-4 mx-auto flex flex-col gap-y-8 w-full urbanist md:w-[1024px]">
        {/* Title */}
        <h3 className='text-[2rem] md:text-[3rem] text-left leading-8  self-start'>Earn a bonus!</h3>

        {/* Responsive layout with gap between divs */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {lstItm.map((item, index) => (
            <div key={index} className="relative w-full h-[300px] flex items-center justify-center rounded-3xl" style={{
              boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1)",
              backgroundImage: "linear-gradient(-45deg, #214e81 12.5%, #506179 25%, #ed5409 37.5%, #ffcb67 50%, #ffcb67 0, #ed5409 62.5%, #506179 75%, #214e81 87.5%)",
              animation: "gradient 3s linear infinite",
              backgroundSize: "200% auto"
            }}>

              <div className="w-[99%] h-[99%] bg-[#181414] rounded-3xl flex flex-col justify-start p-6 gap-y-4">

                <div className="flex items-center gap-x-4">

                  <div className="w-16 h-16">
                    <img src={item.icon} alt={item.title} className="w-full h-full object-contain" />
                  </div>


                  <h1 className="text-xl font-bold text-white">{item.title}</h1>
                </div>

                <p className="text-sm text-gray-300">{item.sub}</p>
              </div>
            </div>
          ))}
        </div>
      </div>


  
      <div className="mt-16 p-4 mx-auto w-full md:w-[800px] font-semibold urbanist">
      
        <h3 className='text-[2.25rem] md:text-[3.25rem] text-center mb-8 text-white'>FAQ</h3>

    
        {faqDta.map((item, index) => (
          <div key={index} className="w-full mb-6">
  
            <button
              onClick={() => toggleQuestion(index)}
              className="flex justify-between items-center border-b border-white py-3 w-full text-left focus:outline-none hover:underline"
              aria-expanded={openIndices.includes(index)}
            >
              <span className="text-white text-lg md:text-xl">{item.question}</span>
              <span className="text-white text-2xl">{openIndices.includes(index) ? '−' : '+'}</span>
            </button>

        
            <div className={`mt-3 text-gray-300 overflow-hidden transition-all duration-300 ${openIndices.includes(index) ? 'max-h-96' : 'max-h-0'}`}>
              <p className="text-base md:text-lg">{item.answer}</p>
            </div>
          </div>
        ))}
      </div>



      <div className="relative mt-80">
        {ftSvg}
      </div>


      <footer className="w-full bottom-0 bg-sonic-black z-30 urbanist">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-16 flex flex-col gap-y-24">

          <div className="md:flex md:justify-between">

            <div className="mb-6 md:mb-0">
              <img src="/asset/sonic-logo.svg"
                className="h-8"
                alt="Logo"
              />
            </div>


            <div className="grid grid-cols-2 gap-8 sm:gap-12 sm:grid-cols-3">
              <div>
                <h2 className="mb-6 font-medium text-shade-light">External</h2>
                <ul className="text-sonic-white font-medium">
                  <li className="mb-4">
                    <a target='_BLANK' href="https://blog.soniclabs.com/" className="hover:underline">
                      Blog
                    </a>
                  </li>
                  <li className="mb-4">
                    <a href="https://docs.soniclabs.com/" className="hover:underline">
                      Docs
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      target='_BLANK'
                      href="https://fantomfoundation.notion.site/4518775d6a7c4b7685e43628431358ab?v=6a6b5746a3204e34ad75e28ea68ac986"
                      className="hover:underline"
                    >
                      Media Kit
                    </a>
                  </li>
                </ul>
              </div>


              <div>
                <h2 className="mb-6 font-medium text-shade-light">Legal</h2>
                <ul className="text-sonic-white font-medium">
                  <li className="mb-4">
                    <a
                      href="https://fantomfoundation.notion.site/Sonic-Website-Term-and-Conditions-87e482091a6443d3ab7476cfa751b977"
                      className="hover:underline"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://fantomfoundation.notion.site/Sonic-Privacy-Policy-9582d39482584ec2a2c04078dad04518"
                      className="hover:underline"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>


          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-body-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2024 Sonic Labs. All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>

    </div>
  );
}
