import { http, createConfig } from "wagmi";
import { fantom } from 'wagmi/chains'
import { metaMask, coinbaseWallet, walletConnect } from 'wagmi/connectors'

const app_name = "Sonic Migration"
const app_description = ""
const app_icon = "https://example.com"
const app_url = "https://example.com"

export const config = createConfig({
    chains: [fantom],
    connectors: [
      metaMask({
        dappMetadata: {
          url: app_url,
          name: app_name,
          iconUrl: app_icon,
        },
        extensionOnly: true,
      }),
      walletConnect({
        projectId: "2c07a9f5ac11ab827ba9599cdaf81ff3",
        relayUrl: 'wss://relay.walletconnect.org',
        metadata: {
          name: app_name,
          description: app_description,
          url: app_url,
          icons: [app_icon]
        },
      }),
      coinbaseWallet({
        appName: app_name,
        appLogoUrl: app_icon
      }),
      ],
    ssr: false,
    transports: {
        [fantom.id]: http(),
    },
  })